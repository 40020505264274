<template>
  <div class="table">
    <div v-if="!$route.path.endsWith('/admin')" class="brand-selection-container">
      <div class="brand-selection">
        <div class="brand-item" :class="{ 'selected-brand': selectedBrand === 'Sientra' }"
          @click="setSelectedBrand('Sientra')">Sientra</div>
        <div class="brand-item" :class="{ 'selected-brand': selectedBrand === 'Allergan' }"
          @click="setSelectedBrand('Allergan')">Allergan</div>
        <div class="brand-item" :class="{ 'selected-brand': selectedBrand === 'Mentor' }"
          @click="setSelectedBrand('Mentor')">Mentor</div>
        <div class="brand-item" :class="{ 'selected-brand': selectedBrand === 'Motiva' }"
          @click="setSelectedBrand('Motiva')">Motiva</div>
      </div>
    </div>

    <transition name="fade">
      <NewForm v-if="showNewForm && model.toolConfig.newForm" :model="model" :scanner="scanner"
        :config="model.toolConfig" :post-loading="postLoading" :type="model.type"
        @on-submit="handleAddSubmission($event)" @toggle-click="handleAddClick($event)" />
    </transition>

    <transition name="fade">
      <UpdateForm v-if="showUpdateForm && model.toolConfig.updateForm" :scanner="scanner" :model="model"
        :selected="selected" :post-loading="postLoading" :config="model.toolConfig" :type="model.type" :identifier="model.selectActions
          ? model.selectActions()['update']['identifier']
          : null
          " @on-submit="handleUpdateSubmission($event)" @on-delete="handleDelete($event)"
        @toggle-click="handleUpdateToggle($event)" />
    </transition>

    <transition name="fade">
      <ProcessForm v-if="showProcessForm && model.toolConfig.processForm" :model="model.processForm"
        :selected="selected" :post-loading="postLoading" :type="model.processForm.type"
        :stepCount="model.processForm.toolConfig.formStepCount" @on-submit="handleProcessSubmission($event)"
        @toggle-click="() => {
          showProcessForm = !showProcessForm;
        }
        " />
    </transition>

    <transition name="fade">
      <DeletesConfirm v-if="showDeletesConfirm" :selected="selected" :post-loading="postLoading"
        :identifier="model.selectActions()['delete']['identifier']" :type="model.type"
        @on-deletes="handleDeletes($event)" @toggle-click="handleDeletesToggle($event)" />
    </transition>

    <transition name="fade">
      <FloatTool @on-action="handleAction" :process="model.toolConfig.processForm" :actions="model.selectActions()"
        v-if="selected.length > 0 && config.multiUpdate" :selected="selected" />
    </transition>

    <TableTools :scanner="scanner" :data="tableData" :config="config" :type="model.type"
      @add-click="handleAddClick($event)" v-model="filter" />

    <div class="row">
      <div class="cell header" :key="k" v-for="(v, k) in model.tableFields()" :style="{
        order: Object.keys(model.tableFields()).indexOf(k),
        justifyContent: getJustfication(k),
      }">
        <div class="key" @click="sortDataBy(k)">
          {{ v.header }}
        </div>

        <div v-html="sortIcon()" v-if="k == activeSort" class="sort-active"></div>
      </div>
    </div>

    <transition-group name="list-complete" tag="ul">
      <div v-for="(row, index) in tableData" @click="toggleSelected(row)" :class="{ selected: selected.includes(row) }"
        class="row list-complete-item" :key="index">
        <div class="cell" :key="k" :style="{
          width:
            'calc(100%/' +
            (Object.keys(model.tableFields()).length + 1) +
            ')',
          order: Object.keys(model.tableFields()).indexOf(k),
          justifyContent: getJustfication(k),
        }" v-for="(v, k) in model.tableFields()">
          <div :class="v.klass" :style="v.styler ? v.styler(row) : ''">
            {{
              v.formatter
                ? v.formatter(row, extractField(row, k))
                : extractField(row, k)
            }}
            <div class="tooltip-content" v-if="v.klass == 'tooltip'">
              {{
                v.formatter
                  ? v.formatter(row, extractField(row, k))
                  : extractField(row, k)
              }}
            </div>
          </div>
        </div>
      </div>
    </transition-group>
    <div class="table-footer-actions">
      <div class="prev-items" :class="{ disabled: (tablePage - 1) < 0 }" @click="prevPage()">Prev</div>
      <div class="cur-row-count">Page {{ tablePage + 1 }} of {{ Math.ceil(currentRowCount / 10) }} ({{ currentRowCount
      }} items)</div>
      <div class="next-items" :class="{ disabled: (tablePage + 1) >= Math.ceil(currentRowCount / 10) }"
        @click="nextPage()">Next</div>
    </div>
  </div>
</template>

<script>
import TableTools from "@/components/TableTools"
import { sort } from "@/utils/svg"
import NewForm from "@/components/NewForm"
import FloatTool from "@/components/FloatTool"
import DeletesConfirm from "@/components/DeletesConfirm"
import ProcessForm from "@/components/ProcessForm"
import UpdateForm from "@/components/UpdateForm"

export default {
  name: "VueTable",
  props: ["scanner", "loading", "postLoading", "model"],
  components: {
    NewForm,
    FloatTool,
    DeletesConfirm,
    ProcessForm,
    UpdateForm,
    TableTools,
  },
  data() {
    return {
      input: [],
      selected: [],
      config: {},
      sortIcon: sort,
      sortAsc: true,
      activeSort: "",
      filter: "",
      filteredData: [],
      showNewForm: false,
      showUpdateForm: false,
      showProcessForm: false,
      showDeletesConfirm: false,
      scannerEnabled: false,
      selectedBrand: 'Sientra',
      tablePage: 0,
    }
  },

  mounted() {
    this.input = this.model.data
    this.config = this.model.toolConfig
    this.scannerEnabled = this.config.scanner
    this.activeSort = Object.keys(this.model.tableFields())[0]
  },

  methods: {
    setSelectedBrand: function (brand) {
      this.selectedBrand = brand
      this.tablePage = 0
    },
    prevPage: function () {
      if ((this.tablePage - 1) < 0) {
        return;
      }
      this.tablePage = this.tablePage - 1;
    },
    nextPage: function () {
      if ((this.tablePage + 1) >= Math.ceil(this.currentRowCount / 10)) {
        return;
      }
      this.tablePage = this.tablePage + 1;
    },
    toggleSelected: function (row) {
      if (row) {
        if (this.config.multiUpdate) {
          if (this.selected.includes(row)) {
            this.selected = this.selected.filter(x => x !== row)
          } else {
            this.selected.push(row)
          }
        }

        if (!this.config.multiUpdate) {
          if (this.selected.includes(row)) {
            this.selected = []
          } else {
            this.selected.push(row)
          }

          this.showUpdateForm = !this.showUpdateForm
        }

        this.$emit("selected", this.selected)
      }
    },

    handleAddClick: function () {
      this.showNewForm = !this.showNewForm
    },

    handleUpdateToggle: function () {
      this.selected = this.config.multiUpdate ? this.selected : []
      this.showUpdateForm = !this.showUpdateForm
    },

    handleDeletesToggle: function () {
      this.showDeletesConfirm = !this.showDeletesConfirm
    },

    handleAction: function (e) {
      if (e == "delete") {
        this.handleDeletesToggle()
      } else if (e == "update") {
        this.showUpdateForm = !this.showUpdateForm
      } else if (
        (e == "process" && this.selected.length >= 1) ||
        this.selected.length <= 2
      ) {
        this.showProcessForm = !this.showProcessForm
      }
    },

    handleAddSubmission: function (e) {
      this.$emit("on-add", e, () => {
        this.showNewForm = !this.showNewForm
      })
    },

    handleUpdateSubmission: function (e) {
      if (this.selected.length == 1) {
        this.$emit("on-update", e, () => {
          this.showUpdateForm = !this.showUpdateForm
        })
      } else {
        this.$emit("on-updates", e, this.selected, () => {
          this.showUpdateForm = !this.showUpdateForm
        })
      }
    },

    handleProcessSubmission: function (e) {
      this.$emit("on-process", e, this.selected, () => {
        this.showProcessForm = !this.showProcessForm
      })
    },

    handleDelete: function (e) {
      this.$emit("on-delete", e, () => {
        this.showUpdateForm = !this.showUpdateForm
      })
    },

    handleDeletes: function (e) {
      this.$emit("on-deletes", e, () => {
        this.showDeletesConfirm = !this.showDeletesConfirm
      })
    },

    getJustfication: function (key) {
      let headers = Object.keys(this.model.tableFields())
      let len = headers.length
      let index = headers.indexOf(key)

      if (index === 0) {
        return "left"
      } else if (index === len - 1) {
        return "flex-end"
      } else {
        return "center"
      }
    },

    sortDataBy: function (key) {
      this.activeSort = key
      this.sortAsc = !this.sortAsc

      this.input = this.input.sort((a, b) =>
        this.sortAsc
          ? this.extractField(a, key).localeCompare(this.extractField(b, key))
          : this.extractField(b, key).localeCompare(this.extractField(a, key))
      )
    },

    extractField: function (row, field) {
      if (Array.isArray(field)) {
        let vals = []

        for (var v in field) {
          vals.push(this.extractField(row, field[v]))
        }

        return vals.join(" ")
      } else if (
        this.model.tableFields()[field] &&
        this.model.tableFields()[field].fields
      ) {
        let values = this.model.tableFields()[field].fields

        return this.extractField(row, values)
      } else {
        if (!field.match(/\./)) {
          return row[field]
        } else {
          let fields = field.split(".")
          let val = row

          for (var f in fields) {
            if (val && val[fields[f]]) {
              val = val[fields[f]]
            } else {
              return "No Data"
            }
          }

          return val
        }
      }
    },

    applyFilter: function () {
      this.tablePage = 0;
      this.filteredData = this.input.filter(x => {
        let search = this.filter.toLowerCase().split(" ")
        let data = JSON.stringify(x).toLowerCase()

        for (var s in search) {
          if (!data.includes(search[s])) {
            return false
          }
        }

        return true
      })
    },
  },

  computed: {
    currentRowCount: function () {
      if (!this.$route.path.endsWith('/admin')) {
        if (!this.filter) {
          return this.input.filter(x => x.brand === this.selectedBrand).length;
        } else {
          return this.filteredData.filter(x => x.brand === this.selectedBrand).length;
        }
      } else {
        if (!this.filter) {
          return this.input.length;
        } else {
          return this.filteredData.length;
        }
      }
    },
    formOpen: function () {
      return (
        this.showUpdateForm ||
        this.showProcessForm ||
        this.showNewForm ||
        this.showDeletesConfirm
      )
    },

    tableData: function () {
      if (this.activeSort) {
        let prop = this.activeSort

        if (!this.$route.path.endsWith('/admin')) {
          if (!this.filter) {
            return this.input.filter(x => x.brand === this.selectedBrand).slice(this.tablePage * 10, this.tablePage * 10 + 10).sort((a, b) =>
              this.sortAsc
                ? this.extractField(a, prop).localeCompare(
                  this.extractField(b, prop)
                )
                : this.extractField(b, prop).localeCompare(
                  this.extractField(a, prop)
                )
            )
          } else {
            return this.filteredData.filter(x => x.brand === this.selectedBrand).slice(this.tablePage * 10, this.tablePage * 10 + 10).sort((a, b) =>
              this.sortAsc
                ? this.extractField(a, prop).localeCompare(
                  this.extractField(b, prop)
                )
                : this.extractField(b, prop).localeCompare(
                  this.extractField(a, prop)
                )
            )
          }
        } else {
          if (!this.filter) {
            return this.input.slice(this.tablePage * 10, this.tablePage * 10 + 10).sort((a, b) =>
              this.sortAsc
                ? this.extractField(a, prop).localeCompare(
                  this.extractField(b, prop)
                )
                : this.extractField(b, prop).localeCompare(
                  this.extractField(a, prop)
                )
            )
          } else {
            return this.filteredData.slice(this.tablePage * 10, this.tablePage * 10 + 10).sort((a, b) =>
              this.sortAsc
                ? this.extractField(a, prop).localeCompare(
                  this.extractField(b, prop)
                )
                : this.extractField(b, prop).localeCompare(
                  this.extractField(a, prop)
                )
            )
          }
        }
      }
    },
  },

  watch: {
    filter() {
      if (this.filter) {
        this.applyFilter()
      }
    },

    "scanner.buffer": function () {
      if (this.scanner.buffer.match(/^.*\]$/) && !this.formOpen) {
        this.scanner.parseData(this.selectedBrand)
        console.log("type: " + this.scanner.type)
        console.log("buffer: " + this.scanner.buffer)
        console.log(`selectedBrand: ${this.selectedBrand || '-'}`);

        let found = this.input.filter(
          x => x.brand == this.selectedBrand && x.serialNumber == this.scanner.buffer
        )

        console.log('found:', found);

        this.toggleSelected(found[0])
      }
    },
  },
}
</script>

<style>
.brand-selection-container {
  display: flex;
  flex-flow: column;
  margin: 0 12px 16px 12px;
  width: 100%;
}

.brand-selection {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-evenly;
}

.brand-item {
  padding: 4px 8px;
  border: 1px solid grey;
  border-radius: 8px;
  cursor: pointer;

  &.selected-brand {
    color: white;
    border: 1px solid #25a3d9;
    background-color: #25a3d9;
    font-weight: 600;
  }
}

.table-footer-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}

.prev-items {
  padding: 4px 8px;
  border: 1px solid grey;
  border-radius: 8px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.next-items {
  padding: 4px 8px;
  border: 1px solid grey;
  border-radius: 8px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  justify-content: center;

  width: 600px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px auto 48px;
}

.row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 56px;

  background: #f1f2f1;
  border: 1px solid #f1f2f1;
  box-sizing: border-box;
  border-radius: 4px;

  cursor: pointer;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px auto 0;
}

.row.list-complete-item {
  transition: background 0.15s ease-in, box-shadow 0.15s ease-in;
}

.row.list-complete-item:nth-child(even) {
  background-color: #ddd;
}

.selected {
  background: #25a3d9bd !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);

  &>div {
    color: white;
  }
}

.cell {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  text-transform: uppercase;

  color: #1f1f1f;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  height: 56px;
}

.header {
  font-weight: bold;
  text-transform: none;
  color: #54575a;
  font-size: 12px;
  line-height: 15px;
}

.key {
  cursor: pointer;
  padding: 10px;
}

.sort-active {
  display: contents;
  position: absolute;
}

.pill {
  padding: 4px;
  min-width: 56px;
  color: white;

  font-weight: bold;
  background: #8c8c8c;
  border-radius: 4px;
}

.used,
.ordered {
  background: #54575a;
}

ul {
  padding: 0;
  margin: auto;
  width: 100%;
}

.no-transform {
  text-transform: none;
}

.no-box-shadow {
  box-shadow: none;
}

@media (max-width: 800px) {
  .table {
    width: 600px;
  }
}

@media (max-width: 640px) {
  .table {
    width: 360px;
  }
}

.fade-enter-active,
.fade-leave-active {
  z-index: 1500;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  z-index: 1500;
  opacity: 0;
}

.scan-buffer {
  left: -999rem;
  position: absolute;
}

.tooltip {
  white-space: nowrap;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-content {
  display: none;
}

.cell:hover .tooltip-content {
  background: #1496bb;
  color: #fff;
  display: block;
  height: content;
  width: 200px;
  text-align: left;
  padding: 20px;
  white-space: initial;
  left: 40%;
  position: absolute;
  border-radius: 4px;
  font-size: 16px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}
</style>
