export default class Scanner {
  constructor() {
    this.buffer = '';
    this.type = null;
    this.scanning = false;
  }

  parseData(selectedBrand) {
    if (selectedBrand === 'Sientra' && this.buffer.match(/^21\d+-?\d*\]$/)) {
      console.log('sentra serial found');
      this.buffer = this.buffer.slice(2, -1);
      this.type = 'serialNumber';
    } else if (selectedBrand === 'Sientra' && this.buffer.match(/^240\d+-\d+\w+\]$/)) {
      console.log('sentra ref found');
      this.buffer = this.buffer.slice(3, -1);
      this.type = 'referenceNumber';
    } else if (this.buffer.match(/^[A-Z]{4}-\d+\]$/)) {
      console.log('allergan or motiva ref found');
      this.buffer = this.buffer.slice(0, -1);
      this.type = 'referenceNumber';
    } else if (this.buffer.match(/^[A-Z]{4}\d+\]$/)) {
      console.log('allergan or motiva ref found');
      this.buffer = this.buffer.slice(0, -1);
      this.type = 'referenceNumber';
    } else if (this.buffer.match(/^[A-Z]{3}-\d+\+\]$/)) {
      console.log('motiva rsf reference number found');
      this.buffer = this.buffer.slice(0, -1);
      this.type = 'referenceNumber';
    } else if (this.buffer.match(/^\d+-?\d*\]$/)) {
      console.log('possibly motiva, allergan, or mentor serial found');
      this.buffer = this.buffer.slice(0, -1);
      this.type = 'serialNumber';
    } else if (this.buffer.match(/^\d*\]$/)) {
      console.log('motiva variant?');
      this.buffer = this.buffer.slice(0, -1);
      this.type = 'serialNumber';
    }
    // else if (this.buffer.match(/^(ssx|SSX)-\d+\]$/)) {
    //   console.log('allergan ref found');
    //   this.buffer = this.buffer.slice(4, -1);
    //   this.type = 'referenceNumber';
    // }
    else {
      console.log('no valid data found');
    }
  }

  handleKeyPress(event) {
    if (event.key === '[' && !this.scanning) {
      console.log('scan beginning');

      this.buffer = '';
      this.type = null;
      this.scanning = true;

      setTimeout(() => {
        console.log('in timeout');

        this.buffer = '';
        this.type = null;
        this.scanning = false;
      }, 500);
    } else if (this.scanning) {
      this.buffer = this.buffer + event.key.toUpperCase();
    }
  }
}
